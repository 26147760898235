<template>
  <div :class="layoutClass" :style="[layoutStyle]" style="z-index: -1000;">
    <img
      :src="content.localPath"
      frameBorder="0"
      :style="contentStyle"
      :scrolling="scroll"
    />
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "PlayerBackground",
  mixins: [layoutMixin],
  components: {},
  props: {
    content: Object,
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },
  computed: {
    contentStyle: function() {
      return {
        "object-fit": "cover",
        width: "100%",
        height: "100%",
      };
    },
  },
  methods: {
    //
  },
};
</script>
